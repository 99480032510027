/* You can add global styles to this file, and also import other style files */

*{margin: 0; padding: 0; border: 0; font-family: 'Montserrat', sans-serif; scroll-behavior: smooth;}









/*
[_ngcontent-bqo-c305].angular-editor, .angular-editor-wrapper, .angular-editor-textarea{
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
}
 */
[_ngcontent-ubs-c305], .angular-editor-textarea{
  border: none !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  resize: none !important;
  //position: relative;
  //padding: 0.5rem 1.8rem 1rem !important;
}



.mat-dialog-container {
  display: block;
  padding: 3px !important;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}


pdf-viewer{
  margin-top: 350px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}


.pdf{
  height: 100vh;
}

.mat-dialog-container {
  display: block;
  padding: 0 !important;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}



@media only screen and (max-width: 667px) {

  .mat-expansion-panel[_ngcontent-psw-c305], .ngx-audio-player {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    min-width: 200px !important;
  }

  pdf-viewer{
    zoom: 0.5 !important;
    margin-top: 700px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;

  }

  [_ngcontent-upn-c315], .ng2-pdf-viewer-container{
    margin-top: -600px !important;
    height: 100vh !important;

  }


  .pdf{
    height: 50vh;
  }

  [_ngcontent-ubs-c305], .angular-editor-textarea{
    margin-left: -10px !important;
  }



  .mat-icon-button {
    padding: 0;
    min-width: 0;
    font-size: 20px !important;
    width: 21px !important;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%;
}

.mat-drawer-inner-container {
  width: 100% !important;
  height: 100% !important;
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch;
}




.angular-editor,.angular-editor-wrapper,.angular-editor-textarea{

}


.angular-editor[_ngcontent-ytc-c305],.angular-editor-wrapper[_ngcontent-ytc-c305],.angular-editor-textarea[_ngcontent-ytc-c305] {

}

.angular-editor-textarea[_ngcontent-vli-c305] {

}



}
